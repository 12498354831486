@font-face {
  font-family:"mr-eaves-xl-sans";
  src:url("https://use.typekit.net/af/37dc55/00000000000000007735b304/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/37dc55/00000000000000007735b304/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/37dc55/00000000000000007735b304/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
  }
  
  @font-face {
  font-family:"mr-eaves-xl-sans";
  src:url("https://use.typekit.net/af/459e96/00000000000000007735b308/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3") format("woff2"),url("https://use.typekit.net/af/459e96/00000000000000007735b308/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3") format("woff"),url("https://use.typekit.net/af/459e96/00000000000000007735b308/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:200;font-stretch:normal;
  }
  
  @font-face {
  font-family:"mr-eaves-xl-sans";
  src:url("https://use.typekit.net/af/82d493/00000000000000007735b30b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/82d493/00000000000000007735b30b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/82d493/00000000000000007735b30b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
  }
  
  @font-face {
  font-family:"mr-eaves-xl-sans";
  src:url("https://use.typekit.net/af/02ef10/00000000000000007735b30f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/02ef10/00000000000000007735b30f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/02ef10/00000000000000007735b30f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:900;font-stretch:normal;
  }
  
  @font-face {
  font-family:"mr-eaves-xl-sans";
  src:url("https://use.typekit.net/af/f374e0/00000000000000007735b312/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/f374e0/00000000000000007735b312/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/f374e0/00000000000000007735b312/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:300;font-stretch:normal;
  }
  
  @font-face {
  font-family:"mr-eaves-xl-sans";
  src:url("https://use.typekit.net/af/4560d4/00000000000000007735b316/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/4560d4/00000000000000007735b316/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/4560d4/00000000000000007735b316/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
  }
  
  @font-face {
  font-family:"mr-eaves-xl-sans";
  src:url("https://use.typekit.net/af/b6ac71/00000000000000007735b319/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/b6ac71/00000000000000007735b319/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/b6ac71/00000000000000007735b319/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:800;font-stretch:normal;
  }
  
  @font-face {
  font-family:"mr-eaves-xl-sans";
  src:url("https://use.typekit.net/af/a468a4/00000000000000007735b31d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/a468a4/00000000000000007735b31d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/a468a4/00000000000000007735b31d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
  }
  
  @font-face {
  font-family:"mr-eaves-xl-sans";
  src:url("https://use.typekit.net/af/1b4b05/00000000000000007735b321/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff2"),url("https://use.typekit.net/af/1b4b05/00000000000000007735b321/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff"),url("https://use.typekit.net/af/1b4b05/00000000000000007735b321/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:100;font-stretch:normal;
  }
  
  @font-face {
  font-family:"mr-eaves-xl-sans";
  src:url("https://use.typekit.net/af/7ce180/00000000000000007735b325/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3") format("woff2"),url("https://use.typekit.net/af/7ce180/00000000000000007735b325/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3") format("woff"),url("https://use.typekit.net/af/7ce180/00000000000000007735b325/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:100;font-stretch:normal;
  }
  
  @font-face {
  font-family:"mr-eaves-xl-sans";
  src:url("https://use.typekit.net/af/dd6774/00000000000000007735b32b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("woff2"),url("https://use.typekit.net/af/dd6774/00000000000000007735b32b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("woff"),url("https://use.typekit.net/af/dd6774/00000000000000007735b32b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:800;font-stretch:normal;
  }
  
  @font-face {
  font-family:"mr-eaves-xl-sans";
  src:url("https://use.typekit.net/af/12a76f/00000000000000007735b32f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("woff2"),url("https://use.typekit.net/af/12a76f/00000000000000007735b32f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("woff"),url("https://use.typekit.net/af/12a76f/00000000000000007735b32f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:200;font-stretch:normal;
  }
  
  @font-face {
  font-family:"mr-eaves-xl-sans";
  src:url("https://use.typekit.net/af/2b8e86/00000000000000007735b332/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/2b8e86/00000000000000007735b332/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/2b8e86/00000000000000007735b332/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
  }
  
  @font-face {
  font-family:"mr-eaves-xl-sans";
  src:url("https://use.typekit.net/af/4e4383/00000000000000007735b338/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/4e4383/00000000000000007735b338/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/4e4383/00000000000000007735b338/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
  }
  
body {
  font-family:"mr-eaves-xl-sans";
}

h1 {
  font-size: 102px;
  color: white;
  font-weight: 900;
}
h2 {
  font-size: 84px;
  color: white;
  font-weight: 900;
}
h3 {
  font-size: 38px;
  color: white;
  font-weight: 900;
}
h4 {
  font-size: 24px;
  color: white;
  font-weight: 700;
}
p{
  font-size: 20px;
  color: white;
  font-weight: normal;
}
.container-width {
  max-width: 1564px;
  margin: 0 auto;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}
/* Track */
::-webkit-scrollbar-track {
   
}
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #723ACB;
  border-radius: 3px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #723ACB;
}
.wrapper {
  /* padding-left: 90px; */
  overflow-x: hidden;
}
/* header */
.header {
  background-color: #000000;
  padding: 0 20px;
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
}
.header .navbar{
  width: 100%;
}
.header-accounts {
  display: block;
}
.mobile-accounts {
  display: none !important;
}
.header-accounts .sign-btn {
  border-radius: 6px;
  background-color: #723ACB;
  border: 1px solid #723ACB;
  color: #EBE2F8;
  font-weight: 700;
  font-size: 16px;
  padding: 7px 19px;
  margin-right: 20px;
}
.header-accounts .sign-btn:hover, .header-accounts .sign-btn:focus, .header-accounts .sign-btn:active {
  background-color: transparent;
  border-color: #723ACB;
  color: #723ACB;
}
.header-accounts .wallet-btn, .header-accounts .wallet-btn:active {
  background-color: transparent;
  border: unset;
  font-size: 16px;
  font-weight: 700;
}
.header .nav-link ,.nav-link .dropdown-toggle{
  color: #fff;
  font-size: 20px;
  margin-right: 25px;
  display: flex;
  align-items: center;
  padding: 0 0;
  background-color: transparent;
  border: none;
}
.nav-link .dropdown-toggle{
  margin-right: 0;
}
.header .nav-link:hover ,.header .nav-link.active ,.header .nav-link.show,.nav-link .dropdown-toggle:hover{
  background-color: transparent;
  color: #fff;
}
.header .nav-link:last-child{
  margin: 0;
}
.header .nav-link .sidebar-lottie{
  margin: 0;
  margin-right: 5px;
  width: 28px;
  height: 28px;
  margin-top: -5px;
}
.header .dropdown .dropdown-item{
  color: #000;
  font-size: 18px;
}
.header .dropdown .dropdown-item:hover{
  background-color: #000;
  color: #fff;
}
.wallet-lottie {
  height: 40px;
  width: 40px;
  overflow: hidden;
}
.wallet-lottie> div {
  transform: scale(3.5);
}
/* section wrapper */

.section-wrapper {
  overflow: hidden;
  height: calc(100vh - 72px);
}
/* sidebar */
.sidebar {
  background-color: #171B21;
  width: 90px;
  padding: 72px 8px 8px 8px;
  height: 100%;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
}
.sidebar-lottie {
  width: 32px;
  height: 32px;
  margin: 0 auto 4px;
}
.sidebar-lottie .sidebar-lottie-icon {
  width: 100%;
  height: 100%;
}
.sidebar-list li {
  padding: 6px 0px;
  margin-bottom: 4px;
  list-style: none;
}
.sidebar-list li:hover {
  background-color: #222933;
  border-radius: 6px;
}
.sidebar-list li p {
  font-size: 14px;
  color: #5E7089;
  font-weight: 700;
  margin-bottom: 0px;
}
/* banner */
.banner {
  background-image: url('../src/assets/images/banner-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  padding-top: 170px;
  width: 100%;
  /* background-color: #0000005e;
  background-blend-mode: color; */
  background-position: 0 60px;
  position: relative;
}
.banner::after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  background-image: linear-gradient(to right, #00000085, transparent);
}

.banner-content {
  position: relative;
  z-index: 9;
}
/* start */
.banner-content h2, .banner-content h4{
  white-space: nowrap;
  overflow: hidden;
}
.banner-content h2 {
  width: 100%;
  -webkit-animation: type 7s steps(40, end);
  animation: type 2s steps(40, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.banner-content h4 {
  width: 100%;
  opacity: 0;
  -webkit-animation: type2 7s steps(40, end);
  animation: type2 2s steps(40, end);
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: .15em solid white;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: .15em solid white;
  }
  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: .15em solid white;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: .15em solid white;
  }
  100% {
    opacity: 1;
    border: none;
  }
}
/* end */
.banner-content h4 {
  margin-bottom: 15px;
}
.banner-content p {
  font-size: 24px;
  color: #fff;
  font-weight: normal;
  line-height: 30px;
  margin-bottom: 70px;
}
.btn-purple {
  border-radius: 8px;
  background: transparent linear-gradient(73deg, #723ACB 0%, #9B60F8 100%);
  color: #E4D7FA;
  padding: 13px 30px;
  border: none;
  font-weight: 800;
  font-size: 20px;
  transition: all ease-in-out 0.7s;
  box-shadow: 0px 13px 14px 0px #9b60f899;
}
.btn-transparent {
  background-color: transparent;
  color: #CFF5EE;
  box-shadow: 0px 0px 10px #31B399;
  border: 2px solid #31B399;
  border-radius: 8px;
  font-weight: 800;
  font-size: 20px;
  padding: 13px 30px;
  }
  .btn-transparent:hover, .btn-transparent:active, .btn-transparent:focus {
    background-color: #31B399 !important;
    border-color: #31B399 !important;
  }
.btn-purple:hover {
  transform: scale(1.1);
}
/* section entries wrapper */
.section-entries-wrapper {
  background: black;
  background-image: url('../src/assets/images/entries-bg.svg');
  background-position: right top;
  background-size: 100%;
  background-repeat: no-repeat;
}
/* entries */
.entries {
  background-image: url('../src/assets/images/entries-effect-bg.png');
  background-repeat: no-repeat;
  background-position: left -130px;
  position: relative;
  padding: 200px 0px 280px;
  margin-top: -1px;
}
.entries-images-two {
  bottom: -40px;
  left: 270px;
}
.entries-images-three {
  top: 0;
  right: 130px;
}
.entries-content {
  padding-left: 100px;
  padding-right: 0px !important;
}
.entries-content, .independentnft-content {
  padding-right: 70px;
}
.entries-content h3, .independentnft-content h3 {
  margin-bottom: 18px;
}
.entries-content p, .independentnft-content p, .hours-move-content p {
  color: #ffffffc7;
  margin-bottom: 55px;
}
.entries-content p a{
  color: #ffffffc7;
}
/* independentnft */
.independentnft {
  padding: 20px 0px 290px;
}
.independentnft-content {
  padding-right: 100px;
}
.independentnft-image-one {
  left: 250px;
  top: 33px;
}
.two-images-wrapper {
  top: 200px;
}
.independentnft-image-one, .independentnft-image-two, .independentnft-image-three {
  animation: bounce 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
@keyframes bounce {
  from {
      transform: translate3d(0, -40px, 0);
  }
  to {
      transform: translate3d(0, 0px, 0);
  }
}
/* hours move */
.hours-move {
  background-color: #0B0C0F;
  padding: 170px 0px;
  margin-top: -1px;
}
.hours-move-content {
  padding-right: 40px;
}
.hours-move-content h4 {
  color: #31B399;
  margin-bottom: 20px;
}
/* win prize */
.win-prize {
  background-image: url('../src/assets/images/win-prize-bg.png');
  background-repeat: no-repeat;
  height: 100vh;
  background-color: #000000;
  background-position: center;
  padding-top: 340px;
  margin-top: -1px;
}
.win-prize-img {
  animation: bounce 2.8s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
@keyframes bounce {
  from {
      transform: translate3d(0, 40px, 0);
  }
  to {
      transform: translate3d(0, 0px, 0);
  }
}
/* footer */
.footer {
  background-color: #000000;
  background-image: url('../src//assets/images/footer-img.png');
  background-position: bottom;
  background-repeat: no-repeat;
  padding: 80px 0px 40px;
  margin-top: -1px;
}
.footer-logo-content {
  padding-right: 110px;
}
.footer-logo-content .logo {
  margin-bottom: 30px;
}
.footer-logo-content p, .copyright p {
  font-size: 18px;
  color: #c4c6d1;
  font-weight: normal;
  margin-bottom: 0px;
}
.footer-list li {
  font-size: 18px;
  color: #c4c6d1;
  line-height: 45px;
  list-style: none;
}
.footer-list li a {
  color: #c4c6d1;
  text-decoration: none;
}
.footer-list li a:hover {
  border-bottom: 1px solid #7E86A8;
}
.copyright {
  padding: 60px 0px 0px;
}
.footer-logo-content-wrapper h4 {
  margin-bottom: 20px;
}
.social-icons-img {
  transition: all ease-in-out 0.6s;
}
.social-icons-img:hover {
  transform: scale(1.2);
}
.content-page-wrapper {
  background-image: url('../src/assets/images/banner-bg.png');
  background-color: #0b0c0fb0;
    padding: 100px 0 50px;
    background-size: cover;
    background-blend-mode: color;
}
.content-page-wrapper .title h1 {
  font-size: 60px;
}
.content-page-wrapper h2 {
  font-size: 40px;
}
/* media queries */
/* 1700 */
@media screen and (max-width: 1700px) {
  h1 {
    font-size: 90px;
  }
  h2 {
    font-size: 74px;
}
  .container-width {
    max-width: 1320px;
  }
  /* banner */
  .banner-content p {
    font-size: 22px;
    margin-bottom: 50px;
}
  .section-entries-wrapper {
    background: black;
    background-image: url("../src/assets/images/entries-bg.svg");
    background-position: right top;
    background-size: cover;
}
/* entries */
.entries {
  background-position: left -155px;
  padding: 150px 0px 230px;
}
.entries-images-three {
  top: 0;
  right: 10px;
}
/* independentnft */
.independentnft {
  padding: 20px 0px 240px;
}
.independentnft-image-one {
  left: 213px;
  top: 45px;
  width: 200px;
}
.independentnft-image-two {
  width: 350px;
}
.independentnft-image-two img, .independentnft-image-three img, .independentnft-image-one img {
  width: 100%;
}
.independentnft-image-three {
  width: 254px;
}
/* hours move */
.hours-move {
  padding: 160px 0px;
}
/* win prize */
.win-prize {
  padding-top: 360px;
}
.btn-purple {
  font-size: 17px;
}
/* footer */
.footer {
  padding: 30px 0px 40px;
}
.social-icons-img {
  width: 47px;
}
}
/* 1450 */
@media screen and (max-width: 1450px) {
  h1 {
    font-size: 64px;
}
  h2 {
    font-size: 55px;
}
h3 {
  font-size: 36px;
}
h4 {
  font-size: 20px;
}
p {
  font-size: 18px;
}
  .container-width {
    max-width: 1070px;
}
/* banner */
.banner {
  padding-top: 150px;
}
.banner-content p {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 38px;
}
/* entries */
.entries {
  background-position: left -135px;
  padding: 100px 0px 150px;
  background-size: 70%;
}
.entries-images-one {
  width: 250px;
}
.entries-images-two {
  bottom: -30px;
  left: 210px;
  width: 203px;
}
.entries-images-three {
  top: -10px;
  right: 30px;
  width: 141px;
}
.entries-content p, .independentnft-content p, .hours-move-content p {
  margin-bottom: 45px;
}
.btn-purple, .btn-transparent {
  font-size: 16px;
  padding: 13px 25px;
}
/* independentnft */
.independentnft-image-two {
  width: 300px;
}
.independentnft-image-three {
  width: 224px;
}
.independentnft-image-one {
  left: 179px;
  top: 60px;
  width: 170px;
}

/* win prize */
.win-prize {
  padding-top: 310px;
  background-size: 100% 100%;
}
/* footer */
.social-icons-img {
  width: 40px;
}
.footer .btn-purple, .btn-transparent {
  font-size: 14px;
  padding: 13px 23px;
}
.copyright {
  padding: 50px 0px 0px;
}
}
/* 1199 */
@media screen and (max-width: 1199px) {
  h1 {
    font-size: 58px;
}
  h2 {
    font-size: 50px;
}
.container-width {
  max-width: 860px;
}
/* banner */
.banner-content p {
  font-size: 19px;
  line-height: 26px;
  margin-bottom: 35px;
}
/* entries */
.entries {
  padding: 100px 0px 110px;
}
.entries-content {
  padding-left: 70px;
}
.entries-images-one {
  width: 220px;
}
.entries-images-two {
  bottom: -20px;
  left: 180px;
  width: 183px;
}
.entries-images-three {
  top: -20px;
  right: 10px;
  width: 121px;
}
/* independentnft */
.independentnft {
  padding: 20px 0px 140px;
}
.independentnft-image-one {
  left: 139px;
  top: 70px;
  width: 150px;
}
.independentnft-content {
  padding-right: 70px;
}
/* hours move */
.hours-move {
  padding: 130px 0px;
}
/* win prize */
.win-prize-img {
  width: 206px;
  margin: 0 auto;
}
.win-prize-img img {
  width: 100%;
  height: 100%;
}
/* footer */
.footer-logo-content .logo {
  margin-bottom: 40px;
}
.footer-list li {
  line-height: 45px;
}
.social-icons-img {
  width: 32px;
}
.content-page-wrapper .title h1 {
  font-size: 50px;
}
.content-page-wrapper h2 {
  font-size: 30px;
}
}
/* 991 */
@media screen and (max-width: 991px) {
  h1 {
    font-size: 46px;
}
h2 {
  font-size: 42px;
}
h4 {
  font-size: 19px;
}
.container-width {
  max-width: 640px;
}
.header .navbar .navbar-toggler {
  background: #fff;
  padding: 3px 5px;
}

.navbar-toggler .navbar-toggler-icon {
  width: 25px;
  height: 25px;
}

.header .navbar .navbar-collapse {
  position: absolute;
  top: 60px;
  width: 100%;
  background: #000;
  left: 0;
}
.header .nav-link {
  font-size: 18px;
  margin-right: 0;
  padding: 7px 10px;
  justify-content: flex-start;
}
/* banner */
.banner-content h4 {
  margin-bottom: 12px;
}
.banner-content p {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 27px;
}
/* section entries wrapper */
.section-entries-wrapper {
  background-size: 100% 100%;
}
/* entries */
.entries {
  background-position: left -80px;
  background-size: 100%;
}
.entries-images-wrapper {
  max-width: 480px;
  margin: 0 auto 70px;
}
.entries-images-three {
  right: 50px;
}
.entries-content {
  padding-left: 0px;
}
.entries-content p, .independentnft-content p, .hours-move-content p {
  margin-bottom: 35px;
}
/* independentnft */
.independentnft {
  padding: 20px 0px 90px;
}
.independentnft-content {
  padding-right: 0px;
  margin-bottom: 70px;
}
.independentnft-content-images {
  max-width: 550px;
  margin: 0 auto;
}
.independentnft-image-one {
  left: 189px;
  top: 0;
  width: 150px;
}
.two-images-wrapper {
  top: 0;
  padding-top: 115px;
}
/* hours move */
.hours-move {
  padding: 80px 0px;
}
.hours-move-content {
  padding-right: 0px;
}
/* win prize */
.win-prize {
  background-size: cover;
}
/* footer */
.footer-logo-content {
  padding-right: 0px;
  margin-bottom: 30px;
}
.social-icons-img {
  width: 48px;
}
.content-page-wrapper .title h1 {
  font-size: 40px;
}
.content-page-wrapper h2 {
  font-size: 24px;
}
}
/* 767 */
@media screen and (max-width: 767px) {
  h3 {
    font-size: 30px;
}
  .container-width {
    max-width: 460px;
}

/* entries */
.entries {
  padding: 80px 0px;
}
.entries-images-three {
  right: 40px;
}
.two-images-wrapper {
  top: 0;
  padding-top: 155px;
}
.two-images-wrapper {
  top: 0;
  padding-top: 125px;
}
.entries-images-wrapper, .independentnft-content-images {
  max-width: 100%;
}
/* independentnft */
.independentnft-image-two {
  width: 250px;
}
.independentnft-image-three {
  width: 164px;
}
.independentnft-image-one {
  left: 159px;
  width: 140px;
}
/* footer */
.social-icons {
  padding-right: 180px;
}
.content-page-wrapper .title h1 {
  font-size: 30px;
}
.content-page-wrapper h2 {
  font-size: 20px;
}
}
/* 575 */
@media screen and (max-width: 575px) {
  h1 {
    font-size: 34px;
}
  h2 {
    font-size: 35px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
  .container-width {
    max-width: 100%;
    padding: 0px 10px;
  }
  .wrapper {
    /* padding-left: 70px; */
}
/* header */
.header-accounts {
  display: none !important;
}
.mobile-accounts {
  display: flex !important;
}
.mobile-user-icon {
  color: white;
  font-size: 18px;
  margin-right: 15px;
}
.logo {
  width: 140px;
}

  /* sidebar */
  .sidebar {
    width: 70px;
}
.sidebar-lottie {
  width: 25px;
  height: 25px;
}
/* banner */
.banner {
  background-size: cover;
  /* background-position: center; */
}
/* entries */
.entries {
  background-position: left -50px;
  padding: 50px 0px 30px;
}
.entries-images-one {
  width: 100%;
  margin-bottom: 10px;
}
.entries-images-two {
  width: 100%;
  position: unset !important;
  margin-bottom: 10px;
}
.entries-images-three {
  width: 100%;
  position: unset !important;
}
/* independentnft */
.independentnft-image-one {
  left: 69px;
  width: 90px;
}
.two-images-wrapper {
  padding-top: 85px;
}
/* win prize */
.win-prize-img {
  width: 176px;
}

/* footer */
.social-icons {
  padding-right: 0px;
}
.copyright {
  padding: 40px 0px 0px;
}
}